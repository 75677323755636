import React, { useEffect } from 'react';
import client from '../../lib/client.js';
import { modules, pathToApi } from '../../lib/connectionConst'
import { userService } from '../user/user.service.js';
import AuthenticationService from '../authentication_service/AuthenticationService.js';
import { UserContext } from '../../user-context';
import { getInvalidFields, ToastErrorMessage } from '../../lib/validation.js';
import { infrastructureFields } from './InfrastructureFields';
import InfrastructureApi from './InfrastructureApi';
import { Button, Form, Modal, Message, Container, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import '../project/ProjectCreateForm.css';
import { Steps, StepGeneralInformation, StepSpecifications, StepKartaUrzadzenia } from './StepsFormContent';
import InfrastructureRequestForm from './request/InfrastructureRequestForm';
import ReactGA from 'react-ga'
import { usePracownicyPwrPageAllQuery } from '../../redux/PracownicyPwrPage'

class InfrastructureCreateForm extends React.Component {
    constructor(props) {
        super(props);

        this.infrastructureId = 0
        this.totalSteps = 2;
        this.state = {
            loading: true,
            currentStep: 1,
            infrastructure: {
                geoSearchData: {},
                fileList: [],
                filesToDelete: []
            },
            invalidFields: [],
            contactFormVisible: false,
            pracownicyPowiazani: {},
            zapisDialogTrigger: 0
        }
    }

    componentDidMount() {
        this.infrastructureId = parseInt(this.props.match.params.infrastructureId, 10);
        const page = document.location.pathname.replace(this.infrastructureId, 'editView')
        ReactGA.pageview(page)

        if (this.infrastructureId > 0) {
            client(pathToApi(modules.INFRASTRUCTURE)+`/${this.infrastructureId}`)
                .then(({ entity }) => {
                    let loggedUser = userService.loggedUser();
                    const infrastructure = { ...this.state.infrastructure, ...entity, _links: entity._links, loggedUserId: loggedUser ? loggedUser.id : null }
                    this.setState({ infrastructure });
                    //console.log('entity.organization', entity.organization)
                    return Promise.all([
                        entity.organization,
                        entity.geoSearchData.catch(e => { }), // early catch bo geo data może nie istnieć
                        this.pobierzPracownikowPowiazanych({ tetaOsobaUzytkownik: infrastructure.tetaOsobaUzytkownik }).catch(e => { }),
                        this.pobierzInfrastruktureRest()
                    ])
                })
                .then(([organizationResp, geoSearchDataResp = {}, pobierzPracownikowPowiazanychResp, pobierzInfrastruktureRestResp]) => {

                    //console.log('pobierzInfrastruktureRestResp', pobierzInfrastruktureRestResp)
                    const opiekun = 
                        InfrastructureApi.opiekunInfrastrukturyFromInfrastructureRestEntity(pobierzInfrastruktureRestResp.entity)
                    const aparaturowiec =
                        InfrastructureApi.aparaturowiecInfrastrukturyFromInfrastructureRestEntity(pobierzInfrastruktureRestResp.entity)
                    // const kierownik =
                    //     InfrastructureApi.aparaturowiecInfrastrukturyFromInfrastructureRestEntity(pobierzInfrastruktureRestResp.entity)
                    //console.log('pobierzInfrastruktureRestResp', pobierzInfrastruktureRestResp.entity)

                    const infrastructure = {
                        ...this.state.infrastructure,
                        //organizationName: organizationResp.entity.nameEnglish,
                        geoSearchData: geoSearchDataResp.entity || {},
                        opiekun, aparaturowiec, 
                        pracownikToInfrastructures: _.get(pobierzInfrastruktureRestResp, 'entity.pracownikToInfrastructures', [{id:-1}]),
                    };
                    this.setState({ infrastructure });
                    this.setState({ pracownicyPowiazani: pobierzPracownikowPowiazanychResp.entity });
                    //console.log('pobierzPracownikowPowiazanychResp', pobierzPracownikowPowiazanychResp)
                })
                .catch((error) => {
                    console.error(error)
                    this.setState({ infrastructure: undefined })
                })
                .finally(() => this.setState({ loading: false }));
        }
        else {
            this.setState({ loading: false });
        }

        //const { data, error, isLoading } = usePracownicyPwrPageAllQuery()
    }

    pobierzPracownikowPowiazanych = (queryObject) => {
        //console.log('pobierzPracownikowPowiazanych', queryObject)
        return client({
            method: 'POST',
            path: '/api/rest/pracownik-pwrs/pobierzPracownikowPowiazanych',
            entity: queryObject,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    pobierzInfrastruktureRest = () => {
        return client({
            method: 'GET',
            path: '/api/rest/infrastructures' + `/${this.infrastructureId}`,
            headers: { 'Content-Type': 'application/json' }
        })
    }

    isFormValid = () => this.state.invalidFields.length === 0;

    handleDismissValidationErrors = () => this.setState({ invalidFields: [] });

    handleInvalidItemClick = clicked => clicked.step && this.setState({ currentStep: clicked.step })

    handleValidateAndSubmit = e => {
        console.log('handleValidateAndSubmit')
        const { infrastructure } = this.state;
        //const handleInvalidItemClick = clicked => clicked.step && this.setState({ currentStep: clicked.step })
        let invalidFields = getInvalidFields(infrastructure, infrastructureFields, this.handleInvalidItemClick);
        if (invalidFields.length > 0) {
            console.log('handleValidateAndSubmit invalidFields', invalidFields)
        }

        this.setState({ invalidFields });

        if (invalidFields.length === 0) {
            this.handleSubmit(infrastructure)
        }
    }
    handleSubmit = infrastructure => {
        this.setState({ loading: true });

        if (this.infrastructureId > 0) {
            this.handleUpdateInfrastructure({ ...infrastructure });
        }
        else {
            this.handleCreateInfrastructure({ ...infrastructure });
        }
    }

    handleCreateInfrastructure = (infrastructure, e) => {
        console.log('handleCreateInfrastructure')
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            infrastructure.userCreateId = loggedUser.id;
            infrastructure.userUpdateId = loggedUser.id;

            if (!infrastructure.tetaOsobaUzytkownik) {
                infrastructure.tetaOsobaUzytkownik = loggedUser.email
            }
        }

        const formData = new FormData();

        infrastructure.fileList.forEach(file => {
            if (file.originFileObj) {
                formData.append('files', file.originFileObj);
            }
        })

        delete infrastructure.fileList;
        formData.set('infrastructure', new Blob([JSON.stringify(infrastructure)], { type: 'application/json' }));
        formData.set('geoSearchData', new Blob([JSON.stringify(infrastructure.geoSearchData)], { type: 'application/json' }));

        client({
            method: 'POST',
            path: pathToApi(modules.INFRASTRUCTURE),
            entity: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.props.onSubmit(response.entity, <span>Infrastructure created: <em>{infrastructure.name}</em></span>, e);
            })
            .catch(error => {
                this.setState({ loading: false });
                toast.error(<ToastErrorMessage
                    message={'Failed to create infrastructure: ' + error.entity.message}
                    violations={error.entity.violations} />, { autoClose: false }
                )
            })
    }

    handleUpdateInfrastructure = (infrastructure, e) => {
        let loggedUser = userService.loggedUser();
        if (loggedUser && loggedUser.id) {
            infrastructure.userUpdateId = loggedUser.id;
        }

        const formData = new FormData();

        infrastructure.fileList.forEach(file => {
            if (file.originFileObj) {
                formData.append('files', file.originFileObj);
            }
        })
        const filesToDelete = infrastructure.filesToDelete.map(o => o.uid);
        infrastructure.id = InfrastructureApi.infrastructureId(infrastructure);

        delete infrastructure.fileList;
        delete infrastructure.filesToDelete;

        formData.set('infrastructure', new Blob([JSON.stringify(infrastructure)], { type: 'application/json' }));
        formData.set('deleted_files', new Blob([JSON.stringify(filesToDelete)], { type: 'application/json' }));
        formData.set('geoSearchData', new Blob([JSON.stringify(infrastructure.geoSearchData)], { type: 'application/json' }));

        client({
            method: 'PATCH',
            path: infrastructure._links.self.href,
            entity: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        })
            .then(response => {
                this.props.onSubmit(infrastructure, <span>Infrastructure updated: <em>{infrastructure.name}</em></span>, e);
            })
            .catch(error => {
                this.setState({ loading: false });
                toast.error(<ToastErrorMessage
                    message={'Failed to update infrastructure: ' + error.entity.message}
                    violations={error.entity.violations} />, { autoClose: false }
                )
            })
    }

    handleDelete = e => {
        const { infrastructure } = this.state;
        client({
            method: 'DELETE',
            path: infrastructure._links.self.href
        })
            .then(response => {
                this.props.onSubmit(infrastructure, <span>Infrastructure deleted: <em>{infrastructure.name}</em></span>, e);
                //this.handleClose();
            })
            .catch(error => {
                this.setState({ loading: false });
                toast.error(<ToastErrorMessage
                    message={'Failed to delete infrastructure: ' + error.entity.message}
                    violations={error.entity.violations} />, { autoClose: false }
                )
            })
    }

    handleClose = e => {
        if (this.props.onClose) {
            this.props.onClose();
        }
        else {
            this.props.history.push('/infrastructures');
        }
    }

    handleInfrastructureChange = changes => {
        //console.log('handleInfrastructureChange', changes)
        const infrastructure = Object.assign({ ...this.state.infrastructure }, changes);
        this.setState({ infrastructure });
    }

    handleStepClick = (e, stepObj) => {
        this.setState({ currentStep: stepObj['data-stepnumber'] })
    }

    handleStepProceed = e => {
        if (this.state.currentStep < this.totalSteps) {
            this.setState(prevState => ({
                currentStep: prevState.currentStep + 1
            }))
        }
    }

    openZapisDialog = () => {
        this.setState({ zapisDialogTrigger: this.state.zapisDialogTrigger + 1 });
    }

    setupButtons = currentStep => {
        let readOnly = !InfrastructureApi.canEdit(this.state.infrastructure)

        let submitButton = <Button color='green' key='submit' icon='checkmark'
            content='Zapisz' onClick={this.handleValidateAndSubmit} disabled={this.state.loading} />;
        let cancelButton = <Button key='cancel' color='red' icon='remove' content='Anuluj' onClick={this.handleClose} />;
        let proceedButton = <Button color='blue' key='proceed' labelPosition='right' icon='chevron right'
            content='Dalej' onClick={this.handleStepProceed} disabled={this.state.loading} />;
        const contactButton = <Button color='blue' key='contact' content='Contact with owner' className='contactWithOwner'
            onClick={() => this.setState({ contactFormVisible: true })} disabled={this.state.loading} />;
        let deleteButton = <Button color='red' key='delete' icon='delete'
            content='Usuń' onClick={this.handleDelete} disabled={this.state.loading} />;
        let zapiszDialogButton = <Button color='green' key='dialog' icon='checkmark'
            content='Zapisz...' onClick={this.openZapisDialog} disabled={this.state.loading} />;

        let buttons = [cancelButton];

        if (readOnly) {
            submitButton = null;
            zapiszDialogButton = null;
        }

        if (this.infrastructureId > 0) {
            //buttons.unshift(submitButton);
            //buttons.unshift(contactButton);
            buttons.unshift(zapiszDialogButton);
            if (userService.isAdmin()) {
                buttons.unshift(deleteButton);
            }
        }
        else {
            switch (currentStep) {
                default:
                case 1:
                    if (!this.state.loading) {
                        buttons.unshift(proceedButton);
                    }
                    break;

                case 2:
                    buttons.unshift(submitButton);
                    break;
            }
        }
        return buttons
    }

    render() {
        if (this.state.infrastructure === undefined) {
            return (
                <Modal open dimmer='inverted' basic>
                    <Message
                        header='Not found'
                        content='The requested infrastructure was not found.'
                    />
                </Modal>
            )
        }

        const { currentStep, infrastructure, invalidFields, pracownicyPowiazani } = this.state;
        //console.log('invalidFields', JSON.stringify(invalidFields))
        const readOnly = !InfrastructureApi.canEdit(infrastructure)
        let buttons = this.setupButtons(currentStep)

        let formContent;
        switch (currentStep) {
            default:
            case 1:
                formContent =
                    <StepGeneralInformation infrastructure={infrastructure}
                        onInfrastructureChange={this.handleInfrastructureChange}
                        invalidFields={invalidFields}
                        readOnly={readOnly}
                    />
                break;

            case 2:
                formContent =
                    <StepKartaUrzadzenia infrastructure={infrastructure}
                        onInfrastructureChange={this.handleInfrastructureChange}
                        invalidFields={invalidFields}
                        readOnly={readOnly}
                        pracownicyPowiazani={pracownicyPowiazani}
                    />
                break;
            case 3:
                formContent =
                    <StepSpecifications infrastructure={infrastructure}
                        onInfrastructureChange={this.handleInfrastructureChange}
                        invalidFields={invalidFields}
                        readOnly={readOnly}
                    />
                break;
        }

        const formRegister =
            <Modal open dimmer={this.props.dimmer} onClose={this.handleClose} closeOnDimmerClick={readOnly}>
                <Modal.Header>
                    {this.props.title}<span className='nameEditLabel'>{infrastructure.name}</span>
                </Modal.Header>

                <Steps currentStep={currentStep} onStepClick={this.handleStepClick} />
                <Form className='attached segment' loading={this.state.loading} error={!this.isFormValid()}>
                    <Message className='validationErrors'
                        error
                        size='small'
                        header='Następujące pola są niewypełnione lub zawierają błędy'
                        list={invalidFields.map(o => o.validationClickableError)}
                        onDismiss={this.handleDismissValidationErrors}
                    />
                    <Modal.Content>
                        {formContent}

                        {
                            this.state.contactFormVisible &&
                            <InfrastructureRequestForm
                                authRequired
                                open={this.state.contactFormVisible}
                                handleClose={() => this.setState({ contactFormVisible: false })}
                                infrastructure={infrastructure}
                            />
                        }
                    </Modal.Content>
                </Form>
                <Message attached='bottom'>
                    <Container textAlign='right'>
                        {buttons}
                    </Container>
                </Message>
                <ZapisDialog zapisDialogTrigger={this.state.zapisDialogTrigger} infrastructure={this.state.infrastructure} 
                    handleInvalidItemClick={this.handleInvalidItemClick} handleSubmit={this.handleSubmit } 
                    onDismissValidationErrors={this.handleDismissValidationErrors}/>
            </Modal>

        return (
            <UserContext.Consumer>
                {({ setLoggedUser }) => (
                    (userService.isAuthenticated() || !this.props.authRequired) ?
                        formRegister
                        :
                        <AuthenticationService setLoggedUser={setLoggedUser} open />
                )}
            </UserContext.Consumer>
        )
    }
}

const ZapisDialog = (props) => {
    const { zapisDialogTrigger, infrastructure, handleInvalidItemClick, handleSubmit, onDismissValidationErrors } = props
    const [open, setOpen] = React.useState(false)
    const [kierownikZaznaczaUzycieKomercyjne, setKierownikZaznaczaUzycieKomercyjne] = React.useState(false)
    useEffect(() => {
        if (zapisDialogTrigger > 0) setOpen(true)
    }, [zapisDialogTrigger]);
    let invalidFields = getInvalidFields(infrastructure, infrastructureFields, handleInvalidItemClick);

    const handleZapiszPrzekazDoNastepnegoEtapu = () => {
        setOpen(false)
        if (kierownikZaznaczaUzycieKomercyjne) infrastructure.mozliwoscUzyciaKomercyjnego = 1
        if (InfrastructureApi.edytujeAutor(infrastructure)) infrastructure.akceptacjaAutora = 1
        if (InfrastructureApi.edytujeAparaturowiec(infrastructure)) infrastructure.weryfikacjaAparaturowca = 1
        if (InfrastructureApi.edytujeKierownik(infrastructure)) infrastructure.akceptacjaOpisu = 1
        handleSubmit(infrastructure)
    }
    const handletylkoZapisz = () => {
        setOpen(false)
        handleSubmit(infrastructure)
    }
    const handleDismissError = (event, data) => {
        setOpen(false)
        onDismissValidationErrors(event, data)
    }
    const handleChangeKierownikZaznaczaUzycieKomercyjne = (e, { value }) => {
        this.setState({ value })
    }
    
    const wystepujaBledy = invalidFields.length != 0
    if (wystepujaBledy) {
        return (
            <Modal dimmer={{ inverted: true }} closeIcon onClose={() => setOpen(false)} open={open} >
                <Modal.Header>Zapis Infrastruktury</Modal.Header>
                <Modal.Content>
                    <Message className='validationErrors'
                        error
                        size='small'
                        header='Następujące pola są niewypełnione lub zawierają błędy'
                        list={invalidFields.map(o => o.validationClickableError)}
                        onDismiss={handleDismissError}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)} color='red'>
                        <Icon name='remove' /> Anuluj
                    </Button>
                </Modal.Actions>
                <Modal.Actions>
                </Modal.Actions>
            </Modal>
        )
    }

    return (
        <Modal dimmer={{ inverted: true }} closeIcon onClose={() => setOpen(false)} open={open} >
            <Modal.Header>Zapis Infrastruktury</Modal.Header>
            <Modal.Content>
                <p>
                    Czy zakończyć edycję i przekazać infrastrukturę do kolejnego etapu?
                </p>
                {
                    InfrastructureApi.czyEtapKierownik(infrastructure)
                    &&
                    <p>
                        Czy aparatura może być przeznaczona do użytku komercyjnego
                    </p>
                }
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' onClick={() => handleZapiszPrzekazDoNastepnegoEtapu()}>
                    <Icon name='checkmark' /> Tak, zapisz i przekaż do kolejnego etapu
                </Button>
                <Button onClick={() => handletylkoZapisz()}>
                    <Icon name='checkmark' /> Tylko zapisz
                </Button>
                <Button onClick={() => setOpen(false)} color='red'>
                    <Icon name='remove' /> Anuluj
                </Button>
            </Modal.Actions>
            <Modal.Actions>
            </Modal.Actions>
        </Modal>
    )
}

InfrastructureCreateForm.defaultProps = {
    title: 'Register your infrastructure',
    dimmer: 'inverted',
    readOnly: true
};

export default InfrastructureCreateForm