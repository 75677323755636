import { isBlank, maxSize } from '../../lib/validation.js';
import { isInteger, isNil, negate } from 'lodash';

export const infrastructureFields = {
    name: {
        label: 'Nazwa infrastruktury', //Name of infrastructure
        sizeMax: 200,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    nameEng: {
        label: 'Name of infrastructure', //Name of infrastructure
        sizeMax: 200,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return `Pole '${this.label}' jest wymagane `
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    organizationId: {
        label: 'Organizacja', //Organization
        //validations: [isNil, negate(isInteger)],
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    description: {
        label: 'Krótki opis infrastruktury', //Short description of Infrastructure
        sizeMax: 3000,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    descriptionEng: {
        label: 'Short description of Infrastructure',
        sizeMax: 750,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    address: {
        label: 'Adres', //Address
        sizeMax: 255,
        //get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    zipCode: {
        label: 'Kod pocztowy', //Zip code
        sizeMax: 255,
        //get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    city: {
        label: 'Miejscowość', //City
        sizeMax: 255,
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    region: {
        label: 'Region',
        step: 1
    },
    country: {
        label: 'Country',
        //validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    researchServices: {
        label: 'Usługi badawcze', //Research Services
        sizeMax: 500,
        step: 1
    },
    possibilitiesToRent: {
        label: 'Możliwości użycia', //Possibilities of use
        //get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    website: {
        label: 'Strona internetowa',
        step: 1
    },
    openKeywords: {
        label: 'Słowa kluczowe', //Open Keywords
        //get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    possibleApplication: {
        label: 'Możliwe zastosowanie', //Possible Application
        sizeMax: 500,
        //get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    location: {
        label: 'Lokalizacja',
        step: 1
    },
    tetaRokProdukcji: {
        label: 'Rok produkcji', //Year of production
        step: 1
    },
    uwagiCib: {
        label: 'Uwagi CIB',
        step: 1
    },
    usagePrice: {
        label: 'Cena użytkowania (PLN)', //Price of usage (EUR)
        //get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    tetaOsobaUzytkownik: {
        label: 'Osoba odpowiedzialna', //Responsible person
        //get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    responsiblePersonEmail: {
        label: 'Osoba odpowiedzialna email',
        //get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    responsiblePersonPhone: {
        label: 'Osoba odpowiedzialna telefon',
        //get validations() { return [isBlank] },
        get validationError() {
            if (isBlank(this.value)) return this.label + ' is required'
            return ''
        },
        step: 1
    },
    imageDescriptionCopyright: {
        label: 'Opis/prawa autorskie', // Description/copyright
        step: 2
    },
}